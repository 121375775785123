import React, { useState } from "react";
import styled from "styled-components";
import { Router, Link as RouterLink } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import CTO_Ninja from "../../assets/CTO_Ninja.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Wrapper = styled.div`
	width: 100%;
	height: 400px;
	flex-shrink: 0;
	background: #0e0d22;
	box-shadow: 0px -4px 9px 0px rgba(0, 0, 0, 0.15);
	color: #ffffff;
	padding: 0 60px;
	padding-bottom: 60px;
	display: flex;
	justify-content: space-evenly;
	@media (max-width: 480px) {
		height: auto;
		padding: 30px 16px;
		flex-direction: column;
		justify-content: start;
	}
`;

const LogoText = styled.h1`
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	@media (max-width: 480px) {
		font-size: 22px;
	}
`;

const DIV = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

const Desc = styled.p`
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px; /* 135.714% */
	margin-top: 28px;
	@media (max-width: 480px) {
		font-size: 14px;
		margin-top: 15px;
		margin-bottom: 40px;
		width: 90%;
	}
`;

export const Copyright = styled.p`
	font-family: DM Sans;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	@media screen {
		font-size: 12px;
		font-weight: 400;
		text-align: center;
	}
`;

export const Text = styled.p`
	font-family: DM Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 24px;
	@media (max-width: 480px) {
		font-size: 15px;
		margin-bottom: 0;
	}
`;

export const Link = styled.p`
	color: #d9d9d9;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
	cursor: pointer;
`;

const LogoWrapper = styled.div`
	width: 286px;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	@media (max-width: 480px) {
		width: 100%;
		align-items: center;
	}
`;

const WebDIV = styled(DIV)`
	@media (max-width: 480px) {
		display: none;
	}
`;

const MobileDiv = styled.div`
	display: none;
	@media (max-width: 480px) {
		display: block;
		margin-bottom: 50px;
	}
`;

const TagDIvComponent = styled.div`
	display: flex;
	width: 40%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;

const Logo = styled.img`
	width: 125px;
	height: 125px;
	flex-shrink: 0;
	margin-bottom: 16px;
	@media (max-width: 480px) {
		width: 63px;
		height: 63px;
		flex-shrink: 0;
		margin-right: 20px;
		margin-bottom: 8px;
	}
`;

const LOGO = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 480px) {
		flex-direction: row;
		align-items: center;
	}
`;

const TagDiv = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div style={{ marginBottom: "20px" }}>
			<TagDIvComponent onClick={() => setIsOpen(!isOpen)}>
				<Text>{title}</Text>
				{isOpen ? (
					<KeyboardArrowUpRoundedIcon />
				) : (
					<KeyboardArrowDownRoundedIcon />
				)}
			</TagDIvComponent>
			{isOpen && children}
		</div>
	);
};

const Footer = ({ setSection }) => {
	return (
		<Wrapper>
			<DIV>
				<LogoWrapper>
					<div>
						<LOGO>
							<Logo src={CTO_Ninja} alt="" />
							<LogoText>CTO Ninja</LogoText>
						</LOGO>
						<Desc>
							We provide UI & UX Design, App Development (Flutter, React Native,
							Android, IOS), Web Development, QA Testing, Virtual CTO
						</Desc>
					</div>
					<MobileDiv>
						<div style={{ marginBottom: "20px" }}>
							<TagDiv title="Company">
								<RouterLink to="/#about-us">
									<Link>About Us</Link>
								</RouterLink>
								<RouterLink to="/#testimonial">
									<Link>Testimonials</Link>
								</RouterLink>
							</TagDiv>
						</div>
						<div style={{ marginBottom: "20px" }}>
							<TagDiv title="Our Services">
								<RouterLink to={"/#service"}>
									<Link>UI/UX Design</Link>
								</RouterLink>

								<RouterLink to={"/#service2"}>
									<Link>Web Development</Link>
								</RouterLink>

								<RouterLink to={"/#service3"}>
									<Link>App Development</Link>
								</RouterLink>
							</TagDiv>
						</div>
						<div style={{ marginBottom: "20px" }}>
							<TagDiv title="Helpful Links">
								<RouterLink to={"/#service1"}>
									<Link>Services</Link>
								</RouterLink>
								<RouterLink to={"/#case-study"}>
									<Link>Case Study</Link>
								</RouterLink>
							</TagDiv>
						</div>
						<div style={{ margin: "30px 0px" }}>
							<Text style={{ marginBottom: "16px" }}>Contact Us</Text>
							<Link style={{ display: "flex" }}>
								<CallOutlinedIcon sx={{ height: "20px", marginRight: "4px" }} />
								+91 7903465947
							</Link>
							<Link style={{ display: "flex" }}>
								<EmailOutlinedIcon
									sx={{ height: "20px", marginRight: "4px" }}
								/>
								shivam@ctoninja.tech
							</Link>
							<Link style={{ display: "flex" }}>
								<LocationOnOutlinedIcon
									sx={{ height: "20px", marginRight: "4px" }}
								/>
								Kanke Block Chowk,
								Arsande Ranchi Ranchi Jharkhand - 834006 India{" "}
							</Link>
						</div>
						<div>
							<Text style={{ marginBottom: "24px" }}>Follow Us</Text>
							<div style={{ display: "flex" }}>
								<Link>
									<a
										href="https://www.linkedin.com/company/cto-ninja/?viewAsMember=true"
										target="_blank"
										rel="noopener noreferrer">
										<LinkedInIcon sx={{ marginRight: "12px" }} />
									</a>
								</Link>
								<Link>
									<a
										href="https://www.instagram.com/ctoninja/"
										target="_blank"
										rel="noopener noreferrer">
										<InstagramIcon sx={{ marginRight: "12px" }} />
									</a>
								</Link>
							</div>
						</div>
					</MobileDiv>
					<Copyright>
						Copyright©2025{" "}
						<span style={{ textDecoration: "underline" }}>CTO Ninja</span>
					</Copyright>
				</LogoWrapper>
			</DIV>
			<WebDIV style={{ justifyContent: "flex-start" }}>
				<div style={{ marginBottom: "68px" }}>
					<Text>Company</Text>
					<RouterLink to={"/#about-us"}>
						<Link>About Us</Link>
					</RouterLink>
					<RouterLink to={"/#testimonial"}>
						<Link>Testimonials</Link>
					</RouterLink>
				</div>
				<div>
					<Text>Helpful Links</Text>
					<RouterLink to={"/#service1"}>
						<Link>Services</Link>
					</RouterLink>
					<RouterLink to={"/#case-study"}>
						<Link>Case Study</Link>
					</RouterLink>
				</div>
			</WebDIV>
			<WebDIV>
				<div>
					<Text>Our Services</Text>
					<RouterLink to={"/#service"}>
						<Link>UI/UX Design</Link>
					</RouterLink>

					<RouterLink to={"/#service2"}>
						<Link>Web Development</Link>
					</RouterLink>

					<RouterLink to={"/#service3"}>
						<Link>App Development</Link>
					</RouterLink>
				</div>
				<div style={{ width: "250px", marginTop: "36px" }}>
					<Text>Contact Us</Text>
					<Link style={{ display: "flex" }}>
						<CallOutlinedIcon sx={{ height: "20px", marginRight: "4px" }} />
						+91 7903465947
					</Link>
					<Link style={{ display: "flex" }}>
						<EmailOutlinedIcon sx={{ height: "20px", marginRight: "4px" }} />
						shivam@ctoninja.tech
					</Link>
					<Link style={{ display: "flex" }}>
						<LocationOnOutlinedIcon
							sx={{ height: "20px", marginRight: "4px" }}
						/>
						C/O Sanjay Sharma, Kailashpuri Arsanday, Kanke Block Chowk, Arsande
						Ranchi Ranchi Jharkhand - 834006 India{" "}
					</Link>
				</div>
			</WebDIV>
			<WebDIV>
				<div>
					<Text>Follow Us</Text>
					<div style={{ display: "flex" }}>
						<Link>
							<a
								href="https://www.linkedin.com/company/cto-ninja/?viewAsMember=true"
								target="_blank"
								rel="noopener noreferrer">
								<LinkedInIcon sx={{ marginRight: "12px" }} />
							</a>
						</Link>
						<Link>
							<a
								href="https://www.instagram.com/ctoninja/"
								target="_blank"
								rel="noopener noreferrer">
								<InstagramIcon sx={{ marginRight: "12px" }} />
							</a>
						</Link>
					</div>
				</div>
			</WebDIV>
		</Wrapper>
	);
};

export default Footer;
