import eventMender from '../../assets/eventMender.svg';
import clapingo from '../../assets/clapingo.svg';
import zavo from '../../assets/zavopay.png';
import kibi from '../../assets/kibi.svg';
import maangal from '../../assets/maangal.svg';
import maangalBazaar from '../../assets/maangalBazaar.svg';
import mayadaar from '../../assets/mayadaar.svg';
import mindEasy from '../../assets/mindEasy.svg';
import musicStar from '../../assets/musicStar.svg';

export const data = [
  {
    img: clapingo,
    text: 'Clapingo',
    area: 'Web Development',
    link: 'https://clapingo.com/',
  },
  {
    img: zavo,
    text: 'Zavo Pay',
    area: 'App Development',
    link: 'https://zavopay.com/',
  }
  ,
  {
    img: kibi,
    text: 'Kibi',
    area: 'Web Development',
    link: 'https://www.kibi.co.in/?gclid=Cj0KCQjwj5mpBhDJARIsAOVjBdrt6kJR1PijniM6KuolkvN79YdhNYNsOGHqbMo8qVNrkWCfrVsejv8aAh-uEALw_wcB',
  },
  {
    img: mindEasy,
    text: 'Mind-Easy',
    area: 'Web Development',
    link: 'https://www.mind-easy.com/',
  },
  {
    img: musicStar,
    text: 'Music Star',
    area: 'Web Development',
    link: 'https://www.musicstar.ai/',
  },
  {
    img: eventMender,
    text: 'Event Mender',
    area: 'Web Development',
    link: 'https://eventmender.com/',
  },
  {
    img: maangal,
    text: 'Maangal',
    area: 'UI/UX Design',
    link: 'https://www.figma.com/file/LMAZEHHaImSwC3NVHBqN4x/Maangal-Matrimonial-App?type=design&t=4WQuu0kV99puKuWl-6',
  },
  {
    img: maangalBazaar,
    text: 'Maangal Bazaar',
    area: 'Web Development',
    link: 'https://mangal-bazar-frontend.vercel.app/',
  },
  {
    img: mayadaar,
    text: 'Mayadaar',
    area: 'UI/UX Design',
    link: 'https://www.figma.com/file/t96ZARqMoK4onnvkF5jPpM/Mayadaar-Dating-App?type=design&t=4WQuu0kV99puKuWl-6',
  },
];
